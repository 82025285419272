import HTTP from './HttpService'

var State = {

  getStates: async function() {
    var states = [];

    await HTTP.http().get('/state')
      .then(function(response) {
        if (response) {
          states = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load states error: ' + error);
      });

      return states;
  },

}

export default State;
