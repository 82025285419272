<template>

  <div class="container">
    <p>
      <span>
        [ <router-link :to="{name: 'Applications'}" class="custom-fg-dark">Return to Application Listing</router-link> ]
      </span>
    </p>

    <div class="card mt-4" id="results">

      <div class="card-header">
        <div class="row">
          <div class="col-9">
            <h5>Fee Administration</h5>
          </div>
          <div class="col-3">
            <input id="filter" placeholder="Filter by State or Loan Purpose" type="text" class="form-control" v-model="searchTerm">
          </div>
        </div>
      </div>
      <table class="table table-sm table-hover mb-0">
        <thead class="custom-bg-med custom-sticky-header sticky-top">
          <tr>
            <th scope="col">State</th>
            <th scope="col">Loan Purpose</th>
            <th scope="col">Admin</th>
            <th scope="col">Processing</th>
            <th scope="col">Escrow</th>
            <th scope="col">Title</th>
            <th scope="col">Appraisal</th>
            <th scope="col">Tax</th>
          </tr>
        </thead>
        <tbody v-cloak>
          <tr v-for="(fee, index) in filteredResult" :key="fee.id">
            <td>{{stateName(fee.stateId)}}</td>
            <td>{{fee.loanPurpose}}</td>
            <td class="text-right">
              <CurrencyInputField v-if="isAdmin" :passedData="{name: 'admin', index: index, value: fee.admin}" @input="changeFee"></CurrencyInputField>
              <label v-if="!isAdmin" class="custom-fg-dark">{{formatCurrency(fee.admin)}}</label>
            </td>
            <td class="text-right">
              <CurrencyInputField v-if="isAdmin" :passedData="{name: 'processing', index: index, value: fee.processing}" @input="changeFee"></CurrencyInputField>
              <label v-if="!isAdmin" class="custom-fg-dark">{{formatCurrency(fee.processing)}}</label>
            </td>
            <td class="text-right">
              <CurrencyInputField v-if="isAdmin" :passedData="{name: 'escrow', index: index, value: fee.escrow}" @input="changeFee"></CurrencyInputField>
              <label v-if="!isAdmin" class="custom-fg-dark">{{formatCurrency(fee.escrow)}}</label>
            </td>
            <td class="text-right">
              <CurrencyInputField v-if="isAdmin" :passedData="{name: 'title', index: index, value: fee.title}" @input="changeFee"></CurrencyInputField>
              <label v-if="!isAdmin" class="custom-fg-dark">{{formatCurrency(fee.title)}}</label>
            </td>
            <td class="text-right">
              <CurrencyInputField v-if="isAdmin" :passedData="{name: 'appraisal', index: index, value: fee.appraisal}" @input="changeFee"></CurrencyInputField>
              <label v-if="!isAdmin" class="custom-fg-dark">{{formatCurrency(fee.appraisal)}}</label>
            </td>
            <td class="text-right">
              <PercentageInputField v-if="isAdmin" :passedData="{name: 'tax', index: index, value: fee.tax}" @input="changeFee"></PercentageInputField>
              <label v-if="!isAdmin" class="custom-fg-dark">{{fee.tax}}</label>
            </td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>

</template>

<script>
import lodash from 'lodash'
import numeral from 'numeral'

import Store from '@/store'

import Fee from '@/services/FeeService'
import State from '@/services/StateService'

import CurrencyInputField from '@/components/util/CurrencyInputField'
import PercentageInputField from "@/components/util/PercentageInputField";

export default {
  name: 'fees',
  components: {
    CurrencyInputField,
    PercentageInputField
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  },
  computed: {
    isAdmin: function() {
      return (Store.state.userInfo != null) ? Store.state.userInfo.admin : false;
    },
    filteredResult: function() {
      return this.fees
          .map(fee => {
            fee.state = this.stateName(fee.stateId)
            return fee
          })
          .filter(result => {
            return (
              result.state.toLowerCase().match(this.searchTerm.toLowerCase()) ||
              result.loanPurpose.toLowerCase().match(this.searchTerm.toLowerCase())
            );
          })
          .sort((a, b) => {
            if (a.stateId === 0) {
              return a;
            }
            if (b.stateId === 0) {
              return b;
            }

            return a.state.localeCompare(b.state)
          });
    },
  },
  created: async function () {
    this.fees = await Fee.getFees();
    this.states = await State.getStates();
  },
  data: function () {
    return {
      fees: [],
      searchTerm: "",
      states: []
    }
  },
  methods: {
    changeFee: async function(returnData) {
      let name = returnData.name;
      let idx = returnData.index;
      let fee = this.filteredResult[idx];

      fee[name] = returnData.newValue;
      this.filteredResult[idx] = await Fee.updateFee(fee);
    },
    formatCurrency: function (amount) {
      return numeral(amount).format('$0,0');
    },
    stateName: function (stateId) {
      if (stateId === 0) {
        return 'Non-Timios county';
      }

      const idx = lodash.findIndex(this.states, {id: stateId});
      const state = this.states[idx];

      return (state != null) ? state.name : "Unknown";
    },
  }
}
</script>
