import HTTP from './HttpService'

var Fee = {

  getFees: async function () {
    var fees = [];

    await HTTP.http().get('/fee')
      .then(function(response) {
        if (response) {
          fees = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load fees error: ' + error);
      });

    return fees;
  },

  updateFee: async function (feeData) {
    let dividedTax = feeData.tax / 100;

    feeData.tax = parseFloat(dividedTax.toFixed(4));

    let responseFee = {};

    await HTTP.http().post('/fee/update', feeData)
      .then(function(result) {
        responseFee = result.data;
      })
      .catch(function(error) {
        console.log('Update fee error: ' + error);
      });

      return responseFee;
  }

}

export default Fee;
